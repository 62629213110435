body {
    background-color: lightgray;
}
.custom_space_checkbox{
    margin-top: 30px;
}

.custom_business_title {
    width: 100%;
    text-align: left;
    box-shadow: none !important;
}

.panel-heading .accordion-toggle:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: #3c8dbc;
}

.panel-heading .accordion-toggle.collapsed:after {
    content: "\e113";
}

.custom_table_list thead th{
    white-space: nowrap;
    color: #000000 !important;
}

.custom_table_list thead th a{
    color: #000000 !important;
}
.custom_table_list tbody .sonata-link-identifier{
    color: #3c8dbc !important;
}
.custom_table_list tbody a{
    color: #000000 !important;
}

.custom_delete_col table th:first-child{
    width: 4% !important;
}

.custom_vich_tab table tbody td:nth-child(3){
   width:40% !important;
}
.custom_vich_tab table tbody .vich-file {
    display: flex;
    align-items: center;
}

.custom_vich_tab table tbody .vich-file .custom_vich_select_file{
   margin-top: 5px;
}
.custom_vich_tab table tbody .vich-file .form-group{
   margin: 0px !important;
}
.custom_show_ul{
    list-style-type: none !important;
}

.custom_general_show{
    width: 50% !important;
}

.row-check {
    width: 5% !important;
    border-right: 1px solid #bacedb;
    padding-right: 20px;
    padding-left: 20px;
}

.row-pbn {
    width: 90%;
}

.w-50-percent {
    width: 50%;
}

.w-45-percent {
    width: 45%;
}

.w-44-percent {
    width: 44%;
}
.w-43-percent {
    width: 43%;
}
#notice .container-fluid{
   max-width: 540px !important;
}

.nav-tabs-custom>.nav-tabs>li>a{
    font-size: 2rem !important;
}

.custom_blue{
    background: #006ba8 !important;
}

.custom_green{
    background: #28a745 !important;
}

.custom_orange{
    background: #ff6727 !important;
}
.margin_10_left{
    margin-left: 10px;
}
.container-fluid  .navbar {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.notice_delete{
    cursor:pointer;
}

.disabledContent
{
    cursor: not-allowed;
    pointer-events:none;
}




.folder {
    /*display: inline-block;*/
    /*margin: 10px;*/
    /*text-align: center;*/
    /*position: relative;*/
    /*width: 80px;*/

    display: inline-block;
    margin: 10px;
    text-align: center;
    position: relative;
    width: 140px;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    padding: 5px;
}

.folder .remove {
    /*visibility: hidden;*/
    /*position: absolute;*/
    /*left: 24px;*/
    /*top: -10px;*/
    /*color: #dc3545;*/

    visibility: hidden;
    position: absolute;
    left: 5px;
    top: 0px;
    color: #dc3545;
}

.folder .contents:hover,
.folder .remove:hover,
.folder .edit:hover {
    cursor: pointer;
}

.folder .edit {
    visibility: hidden;
    /*position: absolute;*/
    /*bottom: 15px;*/
    /*color: #ffc107;*/
    position: absolute;
    top: 0;
    color: #ffc107;
    right: 0;
}

.folder:hover .remove, .folder:hover .edit {
    visibility: visible;
}

.folder span {
    display: block;
    text-align: center;
}

.modal-backdrop {
    background: transparent;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
}

.container-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-btn button {
    margin: 5px;
}

.file span {
    margin-left: 5px;
}

.file-container {
    margin-top: 10px;
}

.file-container table td {
    padding: 5px 0;
}

.file-container table .actions a {
    margin: 0 5px;
}

.search-bar {
    outline: none;
    padding: 5px;
}

.MuiPaper-elevation1 {
    box-shadow: none!important;
    border: 1px solid #e5e5e5!important;
}

.sonata-ba-field-standard-natural .field-actions {
    display: block;
    margin-top: 0;
}


